

.fliperator-page-set-item {
	transform: translate(0px) scale(1);
	will-change: transform, opacity;
	opacity: 1;
}

.fliperator-page-set-item.left {
	transform: translate(-12px, 4px) scale(0.95);
	will-change: transform, opacity;
	opacity: 1;
}

.fliperator-page-set-item.right {
	transform: translate(12px, 4px) scale(0.95);
	opacity: 1;
	will-change: transform;
}