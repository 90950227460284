
.modal-bounds {
	position: "fixed";
	top: "0px";
	left: "0px";
	width: "100%";
	height: "100%";
	bottom: "0px";
	pointer-events: none;
}

.modal-bounds.open {
	pointer-events: auto;
}

.modal-background {
	background-color: #878787;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: opacity 0.5s;
}

.modal-bounds.open .modal-background {
	opacity: 0.3;
}

.modal {
	position: absolute;
	transform: translate(0px 10px);
	transition: transform 0.5s, opacity 0.5s;
	background-color: white;
	/*border-radius: 6px;*/
	padding: 12px 24px;
	opacity: 0;
	box-shadow: 4px 4px 4px rgba(0, 35, 121, 0.2);
}

.modal-bounds.enter-from-left .modal {
	transform: translate(-10px, 0px);
}
.modal-bounds.enter-from-right .modal {
	transform: translate(10px, 0px);
}
.modal-bounds.enter-from-top .modal {
	transform: translate(0px, -10px);
}
.modal-bounds.enter-from-bottom .modal {
	transform: translate(0px, 10px);
}


.modal-bounds.open .modal {
	transform: translate(0px, 0px);
	opacity: 1;
}


.modal-bounds.centered .modal {
	top: 50%;
	left: 50%;
}

.modal-bounds.centered.enter-from-left .modal {
	transform: translate(-50%, -50%) translate(-10px, 0px);
}
.modal-bounds.centered.enter-from-right .modal {
	transform: translate(-50%, -50%) translate(10px, 0px);
}
.modal-bounds.centered.enter-from-top .modal {
	transform: translate(-50%, -50%) translate(0px, -10px);
}
.modal-bounds.centered.enter-from-bottom .modal {
	transform: translate(-50%, -50%) translate(0px, 10px);
}


