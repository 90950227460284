

.float-image {
	animation: Float 15s ease-in-out infinite;
}

@keyframes Float {
  0%, 100% {
    transform: translate(0px, -4px);
  }
  50% {
    transform: translate(0px, 4px);
  }
}

.preview-container {
	animation: 0.5s ease 0s 1 PreviewOpen;
}

@keyframes PreviewOpen {
	0% {
		opacity: 0;
		transform: translateX(5px);
	}
	100% {
		opacity: 1;
		transform: translateX(0px);
	}
}

.preview-shadow {
	box-shadow: 4px 4px 8px rgba(147, 171, 233, 0.3);
}

.dual-image-back-container {
	overflow: hidden;
	transition: 1s  ease;
	transform: translateX(100%);
}

.app.dark .dual-image-back-container {
	transition: 1s ease;
	transform: translateX(0%);
}

.dual-image.back {
	transition: 1s ease;
	transform: translateX(-100%);
}

.app.dark .dual-image.back {
	transition: 1s  ease;
	transform: translateX(0%);
}