

.mouseable-pressable, .mouseable-pressable-light {
	transform: scale(1);
	transition: 0.25s;
	transition-timing-function: ease-out;
}

.mouseable-pressable.hovered {
	transition-timing-function: ease-in;
	transition: 0.2s;
	transform: scale(1.05);
}

.mouseable-pressable.held {
	transition-timing-function: ease-in;
	transition: 0.2s;
	transform: scale(0.95);
}

.mouseable-pressable-light.hovered {
	transition-timing-function: ease-in;
	transition: 0.2s;
	transform: scale(1.01);
}

.mouseable-pressable-light.held {
	transition-timing-function: ease-in;
	transition: 0.2s;
	transform: scale(0.98);
}


.mouseable-pop-entrance {
	animation: 0.5s ease-out 0s 1 PopEntrance;
}


@keyframes PopEntrance {
	0% {
		opacity: 0;
		transform: scale(0.5);
	}
	60% {
		opacity: 1;
		transform: scale(1.1);
	}
	80% {
		transform: scale(0.98);
	}
	100% {
		transform: scale(1);
	}
}