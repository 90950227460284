
.paginated-content-page-base {
	opacity: 0;
	pointer-events: none;
}

.paginated-content-page-base.in {
	opacity: 1;
	pointer-events: auto;
}

.paginated-content-page-enter {
	opacity: 0;
	pointer-events: none;
}

.paginated-content-page-enter-active {
	opacity: 1;
	will-change: opacity;
	transition: opacity 300ms;
	pointer-events: auto;
}

.paginated-content-page-enter-done {
	opacity: 1;
	pointer-events: auto;
}


.paginated-content-page-exit {
	opacity: 1;
	pointer-events: none;
}

.paginated-content-page-exit-active {
	opacity: 0;
	pointer-events: none;
	will-change: opacity;
	transition: opacity 300ms;
}

.paginated-content-page-exit-done {
	opacity: 0;
	pointer-events: none;
}

.paginated-content-position {
	will-change: transform;
	transition: transform 300ms;
	transform: translateX(0%);
}

.paginated-content-position.left {
	transform: translateX(-30%);
}

.paginated-content-position.right {
	transform: translateX(30%);
}