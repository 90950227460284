

.touch-scroll {
	overflow: auto;
}

.touch-scroll-x {
	overflow-x: auto;
}

.touch-scroll-y {
	overflow-y: auto;
}

.touch-scroll.is-touch {
	overflow: scroll !important;
	-webkit-overflow-scrolling: touch !important;
}

.touch-scroll-x.is-touch {
	overflow-x: scroll !important;
	-webkit-overflow-scrolling: touch !important;
}

.touch-scroll-y.is-touch {
	overflow-y: scroll !important;
	-webkit-overflow-scrolling: touch !important;
}