.nav {
	cursor: pointer;
	
	color: #000;
	background-color: white;
	padding: 3px;
	padding-right: 12px;
	margin: 0px;
	width: fit-content;
	/*mix-blend-mode: difference;*/
}

.nav-selected {
	font-weight: bold;
	text-decoration: underline;
}

.nav.secret {
	background-color: #000;
	color: #fff;
}