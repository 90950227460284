@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville:regular,bold,italic&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css?family=Montserrat:regular,medium,bold&subset=latin,latin-ext);

@-webkit-keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinning-icon-rotating {
	-webkit-animation: rotating 1.2s linear infinite;
	        animation: rotating 1.2s linear infinite;
}
html, body {
	margin: 0px;
	padding: 0px;
	font-family: "Libre Baskerville";
}

* {
	box-sizing: border-box;
	color: #2C2C2C;
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, p:first-child {
	margin-top: 0px;
}

a {
	color: #001FFF;
}

h2 {
	margin-top: 12px;
}

h4 {
	text-transform: uppercase;
	letter-spacing: 2px;
}

h5 {
	font-size: 16px;
	margin-bottom: 6px;
	line-height: 24px;
	margin-top: 12px;
}

p, li {
	font-size: 12px;
	line-height: 16px;
	color: #2F2F2F;
}

ul {
	padding-left: 6px;
}

i {
	color: #2F2F2F;
}

.sans {
	font-family: "Montserrat";
}

.page {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	opacity: 0;
	transform: translateX(100px);
	transition: transform 0.5s, opacity 0.5s;
	pointer-events: none;

}
.page.open {
	opacity: 1;
	transform: translateX(0px);
	pointer-events: auto;
	-webkit-animation: 0.5s ease 0s 1 PageOpen;
	        animation: 0.5s ease 0s 1 PageOpen;
}

@-webkit-keyframes PageOpen {
	0% {
		opacity: 0;
		transform: translateX(100px);
	}
	100% {
		opacity: 1;
		transform: translateX(0px);
	}
}

@keyframes PageOpen {
	0% {
		opacity: 0;
		transform: translateX(100px);
	}
	100% {
		opacity: 1;
		transform: translateX(0px);
	}
}

.page-container {
	pointer-events: none;
}
.page-container.open {
	pointer-events: auto;
}

.page-overflow-container {
	padding-right: 27px;
	width: 100%;
	height: 100%;
	overflow: auto;
}

.app {
	width: 100vw;
	height: 100vh;
	background-color: #f3f3f3;
	position: absolute;
	top: 0px;
	left: 0px;
	overflow: hidden;
}

.app.phone {
	height: -webkit-fill-available;
}

.app.phone .page-overflow-container {
	padding-right: 6px;
}

.page-background {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: opacity 1s, transform 6s ease;
	pointer-events: none;
}

.page-background.open {
	opacity: 1;
	pointer-events: auto;
	-webkit-animation: 1s ease 0s 1 BackgroundOpenOpacity, 6s ease 0s 1 BackgroundOpenSize;
	        animation: 1s ease 0s 1 BackgroundOpenOpacity, 6s ease 0s 1 BackgroundOpenSize;
	transform: scale(1);
}

@-webkit-keyframes BackgroundOpenOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes BackgroundOpenOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes BackgroundOpenSize {
	0% {
		transform: scale(1.07);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes BackgroundOpenSize {
	0% {
		transform: scale(1.07);
	}
	100% {
		transform: scale(1);
	}
}




.splash-container {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	overflow: auto;
	transition: 0.5s;
	transform: translateX(0px);
	opacity: 0;
	pointer-events: none;
}

.splash-container.next {
	transform: translateX(80px);
}
.splash-container.previous {
	transform: translateX(-80px);
}

.splash-container.open {
	transform: translateX(0px);
	opacity: 1;
	pointer-events: auto;
}


.background-splash-container {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	transition: 1.5s;
	transform: scale(1.02);
	opacity: 0;
}

.background-splash-container.open {
	transform: scale(1);
	opacity: 1;
}




.flip-button-icon {
	transition: 0.5s;
	transform: rotate(0deg);
}

.flip-button-icon.flipped {
	transform: rotate(180deg);
}


.kete-button-container-base.hidden-initial {
	transform: translateX(80px);
}

.kete-button-container-enter {
	transform: translateX(80px);
}

.kete-button-container-enter-active {
	transform: translateX(0px);
	transition: transform 300ms;
}

.kete-button-container-enter-done {
	transform: translateX(0px);
}

.kete-button-container-exit {
	transform: translateX(0px);
}

.kete-button-container-exit-active {
	transform: translateX(80px);
	transition: transform 300ms;
}

.kete-button-container-exit-done {
	transform: translateX(80px);
}


.float-image {
	-webkit-animation: Float 15s ease-in-out infinite;
	        animation: Float 15s ease-in-out infinite;
}

@-webkit-keyframes Float {
  0%, 100% {
    transform: translate(0px, -4px);
  }
  50% {
    transform: translate(0px, 4px);
  }
}

@keyframes Float {
  0%, 100% {
    transform: translate(0px, -4px);
  }
  50% {
    transform: translate(0px, 4px);
  }
}

.preview-container {
	-webkit-animation: 0.5s ease 0s 1 PreviewOpen;
	        animation: 0.5s ease 0s 1 PreviewOpen;
}

@-webkit-keyframes PreviewOpen {
	0% {
		opacity: 0;
		transform: translateX(5px);
	}
	100% {
		opacity: 1;
		transform: translateX(0px);
	}
}

@keyframes PreviewOpen {
	0% {
		opacity: 0;
		transform: translateX(5px);
	}
	100% {
		opacity: 1;
		transform: translateX(0px);
	}
}

.preview-shadow {
	box-shadow: 4px 4px 8px rgba(147, 171, 233, 0.3);
}

.dual-image-back-container {
	overflow: hidden;
	transition: 1s  ease;
	transform: translateX(100%);
}

.app.dark .dual-image-back-container {
	transition: 1s ease;
	transform: translateX(0%);
}

.dual-image.back {
	transition: 1s ease;
	transform: translateX(-100%);
}

.app.dark .dual-image.back {
	transition: 1s  ease;
	transform: translateX(0%);
}
.shop-background {
    /*background-color: #B9E1FF;*/
    /*background: linear-gradient(180deg, #B9E1FF, #FFE9F9, #FF96E1, #3888C2);*/
    background: linear-gradient(180deg, #B9E1FF, #FFE9F9, #FFE9F9, #B9E1FF);
    background-size: 300% 300%;
    background-position: 0% 0%;
    background-position: 300% 0%;
/*
    -webkit-animation: AnimationName 4s ease infinite;
    -moz-animation: AnimationName 4s ease infinite;
    animation: AnimationName 4s ease infinite;*/

    transition: 0.6s ease;
}

.app.dark .shop-background {
    /*background-color: #FFE9F9;*/
}

.app.dark .shop-background {
    background-position: 0% 100%;
}

@-webkit-keyframes AnimationName {
    0%{background-position:37% 0%}
    50%{background-position:64% 100%}
    100%{background-position:37% 0%}
}
@keyframes AnimationName {
    0%{background-position:37% 0%}
    50%{background-position:64% 100%}
    100%{background-position:37% 0%}
}


.fliperator-page-set-item {
	transform: translate(0px) scale(1);
	will-change: transform, opacity;
	opacity: 1;
}

.fliperator-page-set-item.left {
	transform: translate(-12px, 4px) scale(0.95);
	will-change: transform, opacity;
	opacity: 1;
}

.fliperator-page-set-item.right {
	transform: translate(12px, 4px) scale(0.95);
	opacity: 1;
	will-change: transform;
}
.nav {
	cursor: pointer;
	
	color: #000;
	background-color: white;
	padding: 3px;
	padding-right: 12px;
	margin: 0px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	/*mix-blend-mode: difference;*/
}

.nav-selected {
	font-weight: bold;
	text-decoration: underline;
}

.nav.secret {
	background-color: #000;
	color: #fff;
}


.mouseable-pressable, .mouseable-pressable-light {
	transform: scale(1);
	transition: 0.25s;
	transition-timing-function: ease-out;
}

.mouseable-pressable.hovered {
	transition-timing-function: ease-in;
	transition: 0.2s;
	transform: scale(1.05);
}

.mouseable-pressable.held {
	transition-timing-function: ease-in;
	transition: 0.2s;
	transform: scale(0.95);
}

.mouseable-pressable-light.hovered {
	transition-timing-function: ease-in;
	transition: 0.2s;
	transform: scale(1.01);
}

.mouseable-pressable-light.held {
	transition-timing-function: ease-in;
	transition: 0.2s;
	transform: scale(0.98);
}


.mouseable-pop-entrance {
	-webkit-animation: 0.5s ease-out 0s 1 PopEntrance;
	        animation: 0.5s ease-out 0s 1 PopEntrance;
}


@-webkit-keyframes PopEntrance {
	0% {
		opacity: 0;
		transform: scale(0.5);
	}
	60% {
		opacity: 1;
		transform: scale(1.1);
	}
	80% {
		transform: scale(0.98);
	}
	100% {
		transform: scale(1);
	}
}


@keyframes PopEntrance {
	0% {
		opacity: 0;
		transform: scale(0.5);
	}
	60% {
		opacity: 1;
		transform: scale(1.1);
	}
	80% {
		transform: scale(0.98);
	}
	100% {
		transform: scale(1);
	}
}

@-webkit-keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinning-icon-rotating {
	-webkit-animation: rotating 1.2s linear infinite;
	        animation: rotating 1.2s linear infinite;
}


.load-wheel-container {
	transition: opacity 1s;
	opacity: 0;
}

.load-wheel-container.entered {
	opacity: 1;
}

.modal-bounds {
	position: "fixed";
	top: "0px";
	left: "0px";
	width: "100%";
	height: "100%";
	bottom: "0px";
	pointer-events: none;
}

.modal-bounds.open {
	pointer-events: auto;
}

.modal-background {
	background-color: #878787;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: opacity 0.5s;
}

.modal-bounds.open .modal-background {
	opacity: 0.3;
}

.modal {
	position: absolute;
	transform: translate(0px 10px);
	transition: transform 0.5s, opacity 0.5s;
	background-color: white;
	/*border-radius: 6px;*/
	padding: 12px 24px;
	opacity: 0;
	box-shadow: 4px 4px 4px rgba(0, 35, 121, 0.2);
}

.modal-bounds.enter-from-left .modal {
	transform: translate(-10px, 0px);
}
.modal-bounds.enter-from-right .modal {
	transform: translate(10px, 0px);
}
.modal-bounds.enter-from-top .modal {
	transform: translate(0px, -10px);
}
.modal-bounds.enter-from-bottom .modal {
	transform: translate(0px, 10px);
}


.modal-bounds.open .modal {
	transform: translate(0px, 0px);
	opacity: 1;
}


.modal-bounds.centered .modal {
	top: 50%;
	left: 50%;
}

.modal-bounds.centered.enter-from-left .modal {
	transform: translate(-50%, -50%) translate(-10px, 0px);
}
.modal-bounds.centered.enter-from-right .modal {
	transform: translate(-50%, -50%) translate(10px, 0px);
}
.modal-bounds.centered.enter-from-top .modal {
	transform: translate(-50%, -50%) translate(0px, -10px);
}
.modal-bounds.centered.enter-from-bottom .modal {
	transform: translate(-50%, -50%) translate(0px, 10px);
}




.paginated-content-page-base {
	opacity: 0;
	pointer-events: none;
}

.paginated-content-page-base.in {
	opacity: 1;
	pointer-events: auto;
}

.paginated-content-page-enter {
	opacity: 0;
	pointer-events: none;
}

.paginated-content-page-enter-active {
	opacity: 1;
	will-change: opacity;
	transition: opacity 300ms;
	pointer-events: auto;
}

.paginated-content-page-enter-done {
	opacity: 1;
	pointer-events: auto;
}


.paginated-content-page-exit {
	opacity: 1;
	pointer-events: none;
}

.paginated-content-page-exit-active {
	opacity: 0;
	pointer-events: none;
	will-change: opacity;
	transition: opacity 300ms;
}

.paginated-content-page-exit-done {
	opacity: 0;
	pointer-events: none;
}

.paginated-content-position {
	will-change: transform;
	transition: transform 300ms;
	transform: translateX(0%);
}

.paginated-content-position.left {
	transform: translateX(-30%);
}

.paginated-content-position.right {
	transform: translateX(30%);
}


.touch-scroll {
	overflow: auto;
}

.touch-scroll-x {
	overflow-x: auto;
}

.touch-scroll-y {
	overflow-y: auto;
}

.touch-scroll.is-touch {
	overflow: scroll !important;
	-webkit-overflow-scrolling: touch !important;
}

.touch-scroll-x.is-touch {
	overflow-x: scroll !important;
	-webkit-overflow-scrolling: touch !important;
}

.touch-scroll-y.is-touch {
	overflow-y: scroll !important;
	-webkit-overflow-scrolling: touch !important;
}
