
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinning-icon-rotating {
	animation: rotating 1.2s linear infinite;
}