@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Montserrat:regular,medium,bold&subset=latin,latin-ext');

html, body {
	margin: 0px;
	padding: 0px;
	font-family: "Libre Baskerville";
}

* {
	box-sizing: border-box;
	color: #2C2C2C;
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, p:first-child {
	margin-top: 0px;
}

a {
	color: #001FFF;
}

h2 {
	margin-top: 12px;
}

h4 {
	text-transform: uppercase;
	letter-spacing: 2px;
}

h5 {
	font-size: 16px;
	margin-bottom: 6px;
	line-height: 24px;
	margin-top: 12px;
}

p, li {
	font-size: 12px;
	line-height: 16px;
	color: #2F2F2F;
}

ul {
	padding-left: 6px;
}

i {
	color: #2F2F2F;
}

.sans {
	font-family: "Montserrat";
}

.page {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	opacity: 0;
	transform: translateX(100px);
	transition: transform 0.5s, opacity 0.5s;
	pointer-events: none;

}
.page.open {
	opacity: 1;
	transform: translateX(0px);
	pointer-events: auto;
	animation: 0.5s ease 0s 1 PageOpen;
}

@keyframes PageOpen {
	0% {
		opacity: 0;
		transform: translateX(100px);
	}
	100% {
		opacity: 1;
		transform: translateX(0px);
	}
}

.page-container {
	pointer-events: none;
}
.page-container.open {
	pointer-events: auto;
}

.page-overflow-container {
	padding-right: 27px;
	width: 100%;
	height: 100%;
	overflow: auto;
}

.app {
	width: 100vw;
	height: 100vh;
	background-color: #f3f3f3;
	position: absolute;
	top: 0px;
	left: 0px;
	overflow: hidden;
}

.app.phone {
	height: -webkit-fill-available;
}

.app.phone .page-overflow-container {
	padding-right: 6px;
}

.page-background {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: opacity 1s, transform 6s ease;
	pointer-events: none;
}

.page-background.open {
	opacity: 1;
	pointer-events: auto;
	animation: 1s ease 0s 1 BackgroundOpenOpacity, 6s ease 0s 1 BackgroundOpenSize;
	transform: scale(1);
}

@keyframes BackgroundOpenOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes BackgroundOpenSize {
	0% {
		transform: scale(1.07);
	}
	100% {
		transform: scale(1);
	}
}

