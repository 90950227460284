.shop-background {
    /*background-color: #B9E1FF;*/
    /*background: linear-gradient(180deg, #B9E1FF, #FFE9F9, #FF96E1, #3888C2);*/
    background: linear-gradient(180deg, #B9E1FF, #FFE9F9, #FFE9F9, #B9E1FF);
    background-size: 300% 300%;
    background-position: 0% 0%;
    background-position: 300% 0%;
/*
    -webkit-animation: AnimationName 4s ease infinite;
    -moz-animation: AnimationName 4s ease infinite;
    animation: AnimationName 4s ease infinite;*/

    transition: 0.6s ease;
}

.app.dark .shop-background {
    /*background-color: #FFE9F9;*/
}

.app.dark .shop-background {
    background-position: 0% 100%;
}

@-webkit-keyframes AnimationName {
    0%{background-position:37% 0%}
    50%{background-position:64% 100%}
    100%{background-position:37% 0%}
}
@-moz-keyframes AnimationName {
    0%{background-position:37% 0%}
    50%{background-position:64% 100%}
    100%{background-position:37% 0%}
}
@keyframes AnimationName {
    0%{background-position:37% 0%}
    50%{background-position:64% 100%}
    100%{background-position:37% 0%}
}