

.kete-button-container-base.hidden-initial {
	transform: translateX(80px);
}

.kete-button-container-enter {
	transform: translateX(80px);
}

.kete-button-container-enter-active {
	transform: translateX(0px);
	transition: transform 300ms;
}

.kete-button-container-enter-done {
	transform: translateX(0px);
}

.kete-button-container-exit {
	transform: translateX(0px);
}

.kete-button-container-exit-active {
	transform: translateX(80px);
	transition: transform 300ms;
}

.kete-button-container-exit-done {
	transform: translateX(80px);
}