

.splash-container {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	overflow: auto;
	transition: 0.5s;
	transform: translateX(0px);
	opacity: 0;
	pointer-events: none;
}

.splash-container.next {
	transform: translateX(80px);
}
.splash-container.previous {
	transform: translateX(-80px);
}

.splash-container.open {
	transform: translateX(0px);
	opacity: 1;
	pointer-events: auto;
}


.background-splash-container {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	transition: 1.5s;
	transform: scale(1.02);
	opacity: 0;
}

.background-splash-container.open {
	transform: scale(1);
	opacity: 1;
}

